.car-show-header {
  background-image: linear-gradient(45deg, transparent 0%, #002149a1 0%),
    url(../img/Hodges-car-show-U-shot.webp);
  background-position-y: bottom;
  background-position-x: center;
  background-size: cover;
}

.carShow-glide {
  border-radius: 20px;
  max-height: 322px;
  width: auto;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.425);
  cursor: grab;
}

.padding-20 {
  padding: 20px 0;
}

.height-334 {
  max-height: 334px;
  max-width: 258px;
}

@media screen and (max-width: 1190px) {
  .car-show-header {
    background-image: 
      url(../img/car-show-header-mobile.webp);
      background-size: cover;
  }
}
