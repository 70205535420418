.library-page-header {
    margin: 0 auto;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 80px;
    background-image: linear-gradient(180deg, transparent 75%, white 100%),
      url(../img/library-header.webp);
    background-position: center;
    background-size: cover;
  
    .header-text-container {
        min-height: 450px;
      .top-heading {
        color: white;
        text-shadow: 1px 0px 12px black, -1px 0px 12px black, 0px 1px 12px black, 0px -1px 12px black;
      }
  
      .sub-heading {
        color: white;
        text-shadow: 1px 0px 12px black, -1px 0px 12px black, 0px 1px 12px black, 0px -1px 12px black;
      }
  
      p {
        color: white;
        text-shadow: 3px 3px 5px black;
      }
  
      .inline-link {
        &:hover {
          color: white;
        }
      }
  
      .squircle-small-blue {
        background-color: white;
      }
    }
}